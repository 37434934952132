import * as React from "react";
import styled from "styled-components";

import SHARE_FACEBOOK from "../../../../img/pages/blog/share_facebook.svg";
import SHARE_LINKEDIN from "../../../../img/pages/blog/share_linkedin.svg";
import SHARE_TWITTER from "../../../../img/pages/blog/share_twitter.svg";
import { Dimen } from "../../../constants/legacy";
import { Config } from "../../../constants";

type Props = {
  uid: string | undefined;
};

export default function ShareBlock({ uid }: Props) {
  if (!uid) return null;

  const shareUrl = `${Config.rootUrl}/blog/${uid}`;
  const encoded = encodeURI(shareUrl);

  return (
    <StyledContainer>
      <strong>Share this post</strong>
      <StyledButtons>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encoded}`}
        >
          <img src={SHARE_LINKEDIN} />
        </a>
        <a href={`https://twitter.com/home?status=${encoded}`}>
          <img src={SHARE_TWITTER} />
        </a>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${encoded}`}>
          <img src={SHARE_FACEBOOK} />
        </a>
      </StyledButtons>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 40px 0 70px;

  strong {
    margin-bottom: 20px;
  }

  @media (max-width: ${Dimen.breakpointDefault}px) {
    align-items: flex-start;
  }
`;

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.25s;

  a {
    margin: 0 4px;
    &:hover {
      opacity: 0.75;
    }
  }
`;
