import * as React from "react";
import styled from "styled-components";

import Button from "../Button";
import Modal from "../Modal";
import { useForm } from "react-typed-form";
import { Config, Domain } from "../../../constants";
import { FieldText, SubmitButton, FieldSelect } from "../../../form/legacy";

export default function SpecialSliceDownloadRdfTemplate() {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  return (
    <>
      <StyledSlice>
        <Button
          color="darkBlue"
          label="Download Template"
          onClick={() => setModalVisible(true)}
        />
      </StyledSlice>

      {modalVisible && (
        <Modal
          onDismiss={() => {
            setModalVisible(false);
          }}
        >
          <iframe src="https://go.shipamax.com/l/952713/2022-01-20/w9w" width="100%" height="500" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
        </Modal>
      )}
    </>
  );
}

const StyledSlice = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
`;

const StyledFormSpacer = styled.div`
  height: 20px;
`;
