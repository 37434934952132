import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { Color } from "../../../constants/legacy";
import Modal from "../Modal";

type Props = {
  blogIndexPage: GatsbyTypes.SubscribePromptModalFragment | undefined;
  onDismiss: () => void;
};

export const query = graphql`
  fragment SubscribePromptModal on PrismicBlogIndexPageDataType {
    subscribe_modal_heading
    subscribe_modal_content
    subscribe_modal_first_button
    subscribe_modal_thanks_heading
    subscribe_modal_thanks_content
  }
`;

export default function SubscribePromptModal({
  blogIndexPage,
  onDismiss,
}: Props) {
  const [openedForm, setOpenedForm] = React.useState<boolean>(false);

  if (!blogIndexPage) return null;

  return (
    <Modal onDismiss={onDismiss} fadeInDuration={500}>
      <iframe src="https://go.shipamax.com/l/952713/2022-01-17/rcc" width="100%" height="550" frameBorder="0" allowTransparency={true} style={{ border: 0 }}></iframe>
    </Modal>
  );
}

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  margin: 0;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  border: 1px #ccc solid;
  padding: 0 25px;

  @media (max-width: 400px) {
    padding: 0 10px;
  }
`;

const StyledButton = styled.button`
  box-sizing: border-box;
  height: 60px;
  border: 0;
  background-color: ${Color.darkBlue};
  color: ${Color.white};
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.25s;

  &:hover {
    background-color: ${Color.blue};
  }
`;
