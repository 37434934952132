import { formatDistanceStrict, parseISO } from "date-fns";
import { graphql } from "gatsby";
import * as React from "react";
import {
  calculateReadTime,
  extractBlogSnippet,
  fluidImage,
} from "../../../prismic-tools";
import LinkTile from "../LinkTile";

type Props = {
  postNode: GatsbyTypes.PostTileLegacyFragment;
};

export const query = graphql`
  fragment PostTileLegacy on PrismicBlogPost {
    uid
    data {
      title
      publish_date
      topic_name
      featured_image {
        alt
        dimensions {
          width
          height
        }
        url
      }
      ...ToolsBlogContent
    }
  }
`;

export default function BlogPostTile({ postNode }: Props) {
  const post = postNode.data;
  if (!post) return null;
  const featuredImageFluid = fluidImage(post.featured_image, 400);

  return (
    <LinkTile
      to={`/blog/${postNode.uid}`}
      dark
      imageFluid={featuredImageFluid}
      infoLeft={post.topic_name}
      infoRight={
        post.publish_date &&
        formatDistanceStrict(parseISO(post.publish_date), new Date(), {
          addSuffix: true,
        })
      }
      title={post.title || ""}
      description={extractBlogSnippet(post)}
      readTime={calculateReadTime(post)}
    />
  );
}
