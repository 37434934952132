import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";
import {
  Layout,
  HorizontalContainer,
  Cols,
  Col,
  HelmetSeo,
  FormattedRichText,
} from "../components/legacy";
import { graphql } from "gatsby";
import {
  AuthorBlock,
  PostTile,
  ShareBlock,
  SpecialSliceDownloadRdfTemplate,
} from "../components/legacy/blog";
import { unwrapConnection } from "../util";
import { extractBlogSnippet, fluidImage } from "../prismic-tools";
import { Color } from "../constants/legacy";

export const pageQuery = graphql`
  query BlogPost($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      data {
        title
        topic_name
        featured_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        body {
          __typename
          ... on PrismicBlogPostBodyText {
            primary {
              content {
                raw
              }
            }
          }
          ... on PrismicBlogPostBodyEmbed {
            primary {
              embed {
                embed_url
                html
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            primary {
              image {
                alt
                dimensions {
                  width
                  height
                }
                url
              }
              link_url
            }
          }
          ... on PrismicBlogPostBodySpecial {
            primary {
              content_identifier
            }
          }
        }
        author {
          document {
            __typename
            ... on PrismicBlogAuthor {
              data {
                ...AuthorBlockAuthor
              }
            }
          }
        }
        ...AuthorBlockPost
        ...ToolsBlogContent

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }

    allPrismicBlogPost(
      limit: 3
      sort: { fields: [data___publish_date], order: DESC }
    ) {
      edges {
        node {
          uid
          ...PostTileLegacy
        }
      }
    }

    prismicBlogIndexPage {
      data {
        ...SubscribePromptModal
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.BlogPostQuery;
};

export default function BlogPostTemplate({ data }: Props) {
  // Show a subscription prompt when the user reaches the end of an article
  /* const endReachedRef = React.useRef<HTMLDivElement | null>(null);
  const [hasPrompted, setHasPrompted] = React.useState<boolean>(false);
  const [promptVisible, setPromptVisible] = React.useState<boolean>(false);
  React.useEffect(() => {
    let observer = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio > 0 && !hasPrompted) {
        setHasPrompted(true);
        setTimeout(() => {
          setPromptVisible(true);
        }, 2000);
      }
    });

    endReachedRef.current && observer.observe(endReachedRef.current);

    return () => {
      observer.disconnect();
    };
  }, [endReachedRef.current]); */

  const postNode = data.prismicBlogPost;
  const post = postNode?.data;
  if (!postNode || !post) {
    return null;
  }

  const blogIndexPage = data.prismicBlogIndexPage?.data;
  if (!blogIndexPage) throw new Error("page missing");

  const featuredImageFluid = fluidImage(post.featured_image, 960);

  let author = null;
  if (post.author?.document?.__typename === "PrismicBlogAuthor") {
    author = post.author.document.data;
  }

  const relatedPostNodes = unwrapConnection(data.allPrismicBlogPost);

  return (
    <Layout footerBackColor={Color.lightGrey}>
      {/* The blog post has enough info to create defaults for all of these,
          but let the user override them if they want */}
      <HelmetSeo
        seo_title={post.seo_title || `${post.title} | Shipamax`}
        seo_description={post.seo_description || extractBlogSnippet(post)}
        share_image={post.share_image || post.featured_image}
      />

      <HorizontalContainer>
        <StyledInfoBar>
          <span className="topic">{post.topic_name}</span>
        </StyledInfoBar>

        <StyledTitle>{post.title}</StyledTitle>

        {featuredImageFluid && (
          <StyledFeaturedImage fluid={featuredImageFluid} />
        )}

        <Cols>
          <Col flex={2}>
            <StyledContentWrap>
              {post.body?.map((slice, i) => {
                if (!slice) return null;
                const key = i.toString();
                if (slice.__typename === "PrismicBlogPostBodyText") {
                  if (!slice.primary) {
                    throw new Error("slice.primary is missing");
                  }
                  return (
                    <FormattedRichText
                      key={key}
                      render={slice.primary.content?.raw}
                    />
                  );
                } else if (slice.__typename === "PrismicBlogPostBodyImage") {
                  if (!slice.primary) {
                    throw new Error("slice.primary is missing");
                  }
                  const imageFluid = fluidImage(slice.primary.image, 620);

                  const inner = (
                    <StyledImageEmbed key={key} fluid={imageFluid} />
                  );
                  return slice.primary.link_url ? (
                    <a key={key} href={slice.primary.link_url}>
                      {inner}
                    </a>
                  ) : (
                    inner
                  );
                } else if (slice.__typename === "PrismicBlogPostBodyEmbed") {
                  if (!slice.primary) {
                    throw new Error("slice.primary is missing");
                  }
                  const embed = slice.primary.embed;
                  if (!embed) return null;

                  let matches;
                  if (
                    (matches = embed.embed_url?.match(
                      /youtube\.com\/watch\?v=([a-z0-9_-]+)/i
                    ))
                  ) {
                    return (
                      <StyledVideoIframeEmbed
                        key={key}
                        src={`https://www.youtube.com/embed/${matches[1]}?feature=oembed`}
                        frameBorder={0}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    );
                  } else if (
                    (matches = embed.embed_url?.match(
                      /wistia.com\/medias\/([a-z0-9_-]+)/i
                    ))
                  ) {
                    return (
                      <StyledVideoIframeEmbed
                        key={key}
                        src={`https://fast.wistia.net/embed/iframe/${matches[1]}`}
                        frameBorder={0}
                        allowFullScreen
                      />
                    );
                  }

                  return (
                    <div
                      key={key}
                      dangerouslySetInnerHTML={{ __html: embed.html ?? "" }}
                    />
                  );
                } else if (slice.__typename === "PrismicBlogPostBodySpecial") {
                  if (!slice.primary) {
                    throw new Error("slice.primary is missing");
                  }
                  const identifier = slice.primary.content_identifier;

                  if (identifier === "download_rfp_template") {
                    return <SpecialSliceDownloadRdfTemplate />;
                  } else {
                    throw new Error(
                      `Unknown special content type: ${identifier}`
                    );
                  }
                } else {
                  throw new Error(
                    `Unknown slice type: ${(slice as any).__typename}`
                  );
                }
              })}
            </StyledContentWrap>
          </Col>
          <Col
            flex={1}
            joinedCss="display: flex; flex-direction: column; align-items: flex-end; justify-content: space-between; margin-left: 30px"
          >
            {author ? <AuthorBlock author={author} post={post} /> : <div />}
            <ShareBlock uid={postNode.uid} />
          </Col>
        </Cols>
      </HorizontalContainer>

      {/* Once their viewport intersects with this invisible div,
          we consider it as reaching the end of the artcile */}
      {/* <div ref={endReachedRef} /> */}

      <StyledSectionRelated>
        <HorizontalContainer>
          <h2>You might also like</h2>
          <StyledRelatedTiles>
            {relatedPostNodes.map((postNode) => (
              <PostTile key={postNode.uid} postNode={postNode} />
            ))}
          </StyledRelatedTiles>
        </HorizontalContainer>
      </StyledSectionRelated>

      {/* promptVisible && (
        <SubscribePromptModal
          blogIndexPage={blogIndexPage}
          onDismiss={() => setPromptVisible(false)}
        />
      ) */}
    </Layout>
  );
}

const StyledInfoBar = styled.div`
  padding: 50px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .topic {
    font-size: 14;
    font-weight: 300;
    text-transform: uppercase;
  }
`;

const StyledTitle = styled.h1`
  font-weight: 400;
  font-size: 40px;
  max-width: 800px;
  margin-bottom: 40px;
`;

const StyledFeaturedImage = styled(Img)`
  margin-bottom: 50px;
  height: 400px;
` as any;

const StyledImageEmbed = styled(Img)`
  margin: 40px 0 40px;
` as any;

const StyledVideoIframeEmbed = styled.iframe`
  width: 100%;
  height: 349px;
  margin: 20px 0 40px;
`;

const StyledContentWrap = styled.div`
  padding-bottom: 70px;
`;

const StyledSectionRelated = styled.section`
  padding: 3rem 0;
  background-color: ${Color.lightGrey};

  h2 {
    font-size: 17px;
    text-transform: uppercase;
    text-align: center;
  }
`;

const StyledRelatedTiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px;
`;
