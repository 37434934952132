import { format, parseISO } from "date-fns";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import styled from "styled-components";
import { calculateReadTime, fixedImage } from "../../../prismic-tools";
import { Dimen, Color } from "../../../constants/legacy";

type Props = {
  author: GatsbyTypes.AuthorBlockAuthorFragment;
  post: GatsbyTypes.AuthorBlockPostFragment;
};

export const query = graphql`
  fragment AuthorBlockAuthor on PrismicBlogAuthorDataType {
    first_name
    last_name
    job_title
    avatar_image {
      alt
      dimensions {
        width
        height
      }
      url
    }
  }

  fragment AuthorBlockPost on PrismicBlogPostDataType {
    publish_date
    ...ToolsBlogContent

    tags {
      tag_link {
        document {
          __typename
          ... on PrismicBlogTag {
            data {
              name
            }
          }
        }
      }
    }
  }
`;

export default function AuthorBlock({ author, post }: Props) {
  const tagNames =
    (post.tags
      ?.map((tag) => {
        let tagLink = null;
        if (tag?.tag_link?.document?.__typename === "PrismicBlogTag") {
          tagLink = tag?.tag_link.document.data;
        }
        if (!tagLink) return null;
        return tagLink.name;
      })
      .filter(Boolean) as string[]) || [];

  return (
    <StyledContainer>
      {author.avatar_image && (
        <StyledAvatar
          fixed={fixedImage(author.avatar_image, { w: 86, h: 86 })}
        />
      )}
      <StyledDetails>
        <strong>
          {author.first_name} {author.last_name}
        </strong>
        {author.job_title}

        <StyledPostInfo>
          <div>
            {post.publish_date && format(parseISO(post.publish_date), "MMMM y")}
          </div>
          <div className="min-read">{calculateReadTime(post)} min read</div>

          {tagNames.length > 0 && (
            <StyledTags>
              {tagNames.map((tagName) => (
                <li key={tagName}>{tagName}</li>
              ))}
            </StyledTags>
          )}
        </StyledPostInfo>
      </StyledDetails>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  font-size: 15px;
  margin-bottom: 50px;
`;

const StyledAvatar = styled(Img)`
  border-radius: 50%;
  margin-right: 15px;
` as any;

const StyledDetails = styled.div`
  strong {
    display: block;
    margin-bottom: 3px;
  }
`;

const StyledPostInfo = styled.div`
  margin-top: 20px;
  color: ${Color.grey};
  font-weight: 300;

  @media (max-width: ${Dimen.breakpointDefault}px) {
    .min-read {
      display: none;
    }
  }
`;

const StyledTags = styled.ul`
  margin: 20px 0 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0 0 3px;
    padding: 0;
  }
`;
